
import { defineComponent, ref } from "vue";
import Globals from "@/views/common/Globals.vue";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "ShowApiKeyModal",
  mixins: [Globals],
  data() {
    return {
      public_key: "",
    };
  },
  
  components: {
  },
  methods: {
    callUpdate(public_key) {
      this.public_key = public_key
    },
    closeModal(){
      console.log("close modal is bee")
      this.$emit('close');
    },
    buttonReset() {
      this.$emit('close');
      this.$emit("changeButton", "false");
    },
    copy(text) {
            let copytext = {
                "Public Key": this.public_key,
                
            };
            navigator.clipboard
                .writeText(text.replace(/["]/g, "\\"))
                .then(() => {
                ElNotification({
                    title: "Copy",
                    message: "Copied to Clipboard",
                    type: "success",
                    duration: 2000,
                    position: "top-right",
                });
            })
                .catch(() => {
                ElNotification({
                    title: "Error",
                    message: "Copied to Clipboard failed",
                    type: "warning",
                    duration: 2000,
                    position: "top-right",
                });
            });
    },
  },
  setup() {
    const showEncryptionSecretsModalRef = ref<null | HTMLElement>(null);
    return {
      showEncryptionSecretsModalRef,
    };
  },
  
  
});
